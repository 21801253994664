import React, { memo } from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import groupedIcon from '../../assets/groupedLogo.svg';
import ungroupedIcon from '../../assets/ungroupedLogo.svg';

export type GroupToggleProps = {
  className?: string;
  group: boolean;
  onClick: (isGrouped: boolean) => void;
};

const GroupToggle = (props: GroupToggleProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: '#f7f7f7',
        borderRadius: '4px', // Use 4px as that is what mapbox uses
        marginBottom: '10px',
        marginRight: '10px',
        height: '29px',
        width: '29px',
      }}
    >
      <IconButton
        sx={{
          maxWidth: '29px',
          maxHeight: '29px',
          minHeight: '29px',
          minWidth: '29px !important',
          backgroundColor: '#FFFFFF',
          boxShadow: ' 0 0 0 2px rgb(0 0 0/10%)',
        }}
        type="button"
        title={props.group ? t('Ungroup') : t('Group')}
        onClick={(_) => props.onClick(!props.group)}
        size="large"
      >
        {props.group && (
          <img
            alt="group-icon"
            src={groupedIcon}
            style={{
              alignContent: 'center',
              maxWidth: '33px',
              maxHeight: '33px',
              minHeight: '33px',
              minWidth: '33px',
            }}
          />
        )}
        {!props.group && (
          <img
            alt="ungroup-icon"
            src={ungroupedIcon}
            style={{
              maxWidth: '33px',
              maxHeight: '33px',
              minHeight: '33px',
              minWidth: '33px',
            }}
          />
        )}
      </IconButton>
    </Box>
  );
};

export default memo(GroupToggle);
