import React from 'react';
import { Stack } from '@mui/material';

import LanguageSelectionMenu from '../LanguageSelection/LanguageSelection';
import MapStyleSelection from '../MapStyleSelection/MapStyleSelection';



const AppOptions = () => {  
  return (
    <Stack direction= {{xs:"column", sm: "row"}} spacing={2} zIndex={7}>
      <LanguageSelectionMenu open={false} />
      <MapStyleSelection />
    </Stack>
  );
};

export default AppOptions;
