import { MapBoxData } from './SportsLocation/SportsLocation.service';

export interface SearchParams {
  proximity?: { longitude: number; latitude: number };
  country?: string;
  bbox?: number[];
  types: string;
  limit?: number;
  autocomplete?: boolean;
  language?: string;
}

export default async function searchMapBoxLocation(
  accessToken: string,
  query: string,
  onResult?: (
    err: any,
    res: Response | null | MapBoxData,
    searchTime: Date
  ) => void,
  searchParams: SearchParams = { types: 'country,region,place,postcode' }
) {
  const endpoint: string = 'https://api.mapbox.com';
  const source: string = 'mapbox.places';
  const searchTime = new Date();
  const { proximity, country, bbox, types, limit, autocomplete, language } =
    searchParams;
  try {
    const baseUrl = `${endpoint}/geocoding/v5/${source}/${query}.json`;
    // Don't send empty query params to Mapbox geocoding api.
    const searchParams = {
      ...(isNotNil(accessToken) && { access_token: accessToken }),
      ...(isNotNil(proximity) && {
        proximity:
          proximity && Object.keys(proximity).length === 2
            ? `${proximity.longitude},${proximity.latitude}`
            : null,
      }),
      ...(isNotNil(bbox) && {
        bbox: bbox && bbox.length > 0 ? bbox.join(',') : null,
      }),

      ...(isNotNil(types) && {
        types,
      }),
      ...(isNotNil(country) && {
        country,
      }),
      ...(isNotNil(limit) && {
        limit,
      }),
      ...(isNotNil(autocomplete) && {
        autocomplete,
      }),
      ...(isNotNil(language) && {
        language,
      }),
    };
    const url = `${baseUrl}?${toUrlString(searchParams)}`;
    const res = await fetch(url);
    const data = await res.json();
    if (onResult) onResult(null, data, searchTime);
    return data;
  } catch (err) {
    if (onResult) onResult(err, null, searchTime);
    return { err, res: null, searchTime };
  }
}

function toUrlString(params: any) {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    )
    .join('&');
}

function isNotNil(value: unknown) {
  return value !== undefined && value !== null;
}
