import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SportsLocation } from '../../services/SportsLocation/SportsLocation.service';
import { drawerWidth } from '../LocationsFilter/LocationsFilter';

interface LiceningProps {
  sportsLocation: SportsLocation;
  downloadStarted: boolean;
}

const Licensing = (props: LiceningProps) => {
    const {t} = useTranslation();
    const firstPhrase = `${t('DownloadedPlan')} `;
    const secondPhrase = ` ${t('mayBeUsed')}`;
  return (
    <Box maxWidth={drawerWidth}  pl={2} pr={2}>
      <Typography variant={'caption'}>
        {`${firstPhrase}'${props.sportsLocation.names[0].shortName}'${secondPhrase}`}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode"
        >
          {' '}
          CC BY-NC-SA{' '}
        </Link>
        {t('license')}.
      </Typography>
    </Box>
  );
};

export default Licensing;
