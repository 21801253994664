import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  useMediaQuery,
  Switch,
} from '@mui/material';
import { useQueryParam } from 'use-query-params';
import { useTheme } from '@mui/material';
import { FilterProps } from '../../services/Filters/Filters.service';

interface ToggleFilterProps {
  label: string;
  defaultValue: boolean;
  element?: string;
}

const ToggleFilter = (
  props: ToggleFilterProps &
    FilterProps<boolean | null | undefined, boolean | null | undefined>
) => {
  const {
    label,
    defaultValue,
    queryParam,
    queryParamType,
    sportsLocations,
    evaluator,
  } = props;
  const [isChecked, setIsChecked] = useQueryParam(queryParam, queryParamType);

  // The default value has to be set once for the three options
  // explained below to work.
  React.useEffect(() => {
    if (isChecked === undefined) setIsChecked(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const value = 'enabled';
  const appliesToTracks =
    sportsLocations !== undefined
      ? sportsLocations.filter((l) => evaluator(isChecked, l)).length
      : 0;

  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    // Since this component allows to turn a certain filter on or off
    // it only needs to be in the url if it is not put to its default
    // value. Otherwise we might end up with the opposite of the filter
    // being applied.
    // E.g.: (3. is unwanted by this component)
    // 1. Show SmarTracks-enabled tracks --> isChecked set to true
    // 2. Show all tracks --> isChecked set to undefined
    // 3. Show non-smartracks enabled tracks only --> isChecked set to false
    if (checked !== defaultValue) setIsChecked(checked);
    else setIsChecked(undefined);
  };

  return (
    <FormControlLabel
      sx={{
        flexGrow: 0,
        marginY: theme.spacing(-0.5),
        justifyContent: ['space-between', 'normal'],
        marginLeft: [0, -2],
      }}
      control={
        mobileScreen && props.element ? (
          <Switch
            size={'small'}
            sx={{ marginRight: '16px' }}
            checked={
              isChecked === null || isChecked === undefined
                ? defaultValue
                : isChecked
            }
            onChange={handleChecked}
          />
        ) : (
          <Checkbox
            size={'small'}
            sx={{ color: '#F49C08' }}
            checked={
              isChecked === null || isChecked === undefined
                ? defaultValue
                : isChecked
            }
            onChange={handleChecked}
          />
        )
      }
      disabled={appliesToTracks === 0}
      value={value}
      label={
        <Typography>
          {label}{' '}
          <Box
            component={'span'}
            sx={{ color: '#F49C08' }}
          >{`[${appliesToTracks}]`}</Box>
        </Typography>
      }
      labelPlacement={mobileScreen && props.element ? 'start' : 'end'}
    />
  );
};

export default ToggleFilter;
