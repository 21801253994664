import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BooleanParam,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Autocomplete,
  InputBase,
  IconButton,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import {
  SportsLocation,
  Feature,
} from '../../services/SportsLocation/SportsLocation.service';
import ApiConfig from '../../services/apiConfiguration';

import searchMapBoxLocation from '../../services/fetchMapBox';
import CloseIcon from '@mui/icons-material/Close';
import { defaultLatLngZoom } from '../Map/Map';
import { MapRef } from 'react-map-gl';
import {
  QUERY_PARAM_SMARTRACKS_ENABLED,
  QUERY_PARAM_INDOOR_OUTDOOR,
  QUERY_PARAM_AREA_TYPE,
  QUERY_PARAM_SURFACE_TYPE,
  QUERY_PARAM_AGILITY,
  QUERY_PARAM_FILTER_APPLIED,
} from '../../services/Filters/Filters.service';

const MAPBOX_TOKEN = ApiConfig.MAPBOX_TOKEN || '';

interface SearchLocationProps {
  sportsLocations?: SportsLocation[];
  onLocationSelected?: (location: SportsLocation | undefined) => void;
  onViewportChanged?: (feature: Feature) => void;
  onRegionSelected?: (regionFilter: string | number[] | undefined) => void;
  mobileButton?: boolean;
  inputSet: (i: string) => void;
  mapRef: React.RefObject<MapRef>;
  inputCleanedCallback?: (inputState: string) => void;
}

const SearchLocation = (props: SearchLocationProps) => {
  const {
    sportsLocations,
    onLocationSelected,
    onRegionSelected,
    onViewportChanged,
    mapRef,
  } = props;

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [searchText, setSearchedText] = useQueryParam('search', StringParam);
  const [facility, setFacility] = useQueryParam('facility', StringParam);

  const placeholder = t('SearchAmongSportsLocations');
  const maxSuggestions = 7;

  /**
   * Value of the Autocomplete component, set when a user chooses a value
   */
  const [value, setValue] = React.useState<Feature | null>(null);

  /**
   * Options presented by the Autocomplete component as suggestions to the inputValue
   */
  const [options, setOptions] = React.useState<readonly Feature[]>([]);

  /**
   * Input value of the entry field of the Autocomplete component
   */
  const [inputValue, setInputValue] = React.useState('');

  const centerLocationFeature = {
    id: 'BACKUP',
    type: 'backup_type',
    place_name: `${inputValue}`,
    place_type: ['BackUp'],
    center: [defaultLatLngZoom.longitude, defaultLatLngZoom.latitude],
    geometry: {
      type: 'center_map',
      coordinates: [defaultLatLngZoom.longitude, defaultLatLngZoom.latitude],
    },
    properties: {
      smartracks: false,
    },
  };
  /**
   * Using lodash's debounce function requires React.useMemo
   * in order to persist the function between renders.
   * This debounced callback is used to debounce user input
   * and perform lookups only after a certain amount of time between
   * keystrokes has elapsed.
   */
  const debouncedCallback = React.useMemo(
    () =>
      debounce((input: string, callback: (input: string) => void) => {
        callback(input);
      }, 200),
    []
  );
  const [filters, setFilters] = useQueryParams({
    [QUERY_PARAM_SMARTRACKS_ENABLED]: BooleanParam,
    [QUERY_PARAM_INDOOR_OUTDOOR]: StringParam,
    [QUERY_PARAM_AREA_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_SURFACE_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_AGILITY]: DelimitedArrayParam,
    [QUERY_PARAM_FILTER_APPLIED]: NumberParam,
  });

  /**
   * Queries an array of sports locations and returns the ones that match the given
   * queryString in any of their name, address, country, etc.
   * @param queryString String to find in the sports locations description
   * @param sportsLocations Sports locations to query
   * @returns An array of map features if any are found, otherwise undefined.
   */
  const searchSportsLocations = (
    queryString: string,
    sportsLocations: SportsLocation[]
  ): Feature[] | undefined => {
    try {
      var regex = new RegExp(queryString, 'i');
      let localResults = sportsLocations.filter((l) => {
        return (
          l.names.find(
            (n) =>
              (n.shortName && regex.test(n.shortName)) ||
              (n.formalName && regex.test(n.formalName)) ||
              (n.nickName && regex.test(n.nickName))
          ) ||
          l.addresses.find(
            (a) =>
              (a.city && regex.test(a.city.trim())) ||
              (a.country && regex.test(a.country.name)) ||
              (a.district && regex.test(a.district)) ||
              (a.streetAndNumber && regex.test(a.streetAndNumber)) ||
              (a.state && regex.test(a.state.trim()))
          ) ||
          (l.facilityTitleLine1 && regex.test(l.facilityTitleLine1)) ||
          (l.facilityTitleLine2 && regex.test(l.facilityTitleLine2)) ||
          (l.facilityTitleLine3 && regex.test(l.facilityTitleLine3))
        );
      });

      if (localResults && localResults.length > 0) {
        var features = localResults?.map((r: SportsLocation) => {
          var name = `${r.names[0].shortName}, ${r.addresses[0].streetAndNumber}, ${r.addresses[0].city}, ${r.addresses[0].postalCode}, ${r.addresses[0].country.name}`;
          name = name
            .split(',')
            .filter((v) => v !== null && v !== ' ')
            .join(', ');
          return {
            id: r.sportsLocationGUID,
            type: 'Feature',
            place_name: `\u{1F3C3} ${name}`,
            place_type: ['poi'],
            center: [r.anchorLocation.longitude, r.anchorLocation.latitude],
            geometry: {
              type: 'Point',
              coordinates: [
                r.anchorLocation.longitude,
                r.anchorLocation.latitude,
              ],
            },
            properties: {
              smartracks: true,
            },
          };
        });
        return features;
      }
    } catch (err) {
      console.log('Error while searchinig the local sports locations');
      return;
    }
  };

  /**
   * Handles a change of the value of the Autocomplete component
   * @param feature Selected feature in the Autocomplete component
   */
  const handleAutocompleteChange = (feature: Feature | null) => {
    if (feature !== undefined && feature !== null) {
      const sportsLocationForFeature = sportsLocations?.find(
        (location: { sportsLocationGUID: any }) => {
          return location.sportsLocationGUID === feature.id;
        }
      );
      if (sportsLocationForFeature && onLocationSelected) {
        let alreadyUsedFilter = filters;
        let key: keyof typeof alreadyUsedFilter;
        for (key in alreadyUsedFilter) alreadyUsedFilter[key] = undefined;
        setFilters(alreadyUsedFilter);
        setSearchedText(undefined);
        onLocationSelected(sportsLocationForFeature);
      } else if (feature && onLocationSelected) {
        onLocationSelected(undefined);
        setSearchedText(feature.place_name);

        if (feature.place_type[0] === 'country' && onRegionSelected)
          onRegionSelected((feature.properties as any).short_code);
        else if (onRegionSelected) onRegionSelected(feature.bbox);
      } else {
        setSearchedText(encodeURIComponent(feature?.place_name ?? ''));
      }
      if (onViewportChanged)
        onViewportChanged(
          feature === undefined ? centerLocationFeature : feature
        );
    } else {
      setSearchedText(undefined);
      if (onRegionSelected) onRegionSelected(undefined);
    }
    setValue(feature);
  };
  React.useEffect(() => {
    let active = true;
    if (inputValue.length > 2) {
      if (sportsLocations) {
        const searchResults = searchSportsLocations(
          inputValue,
          sportsLocations
        );
        if (active)
          setOptions((o) =>
            searchResults ? searchResults.slice(0, maxSuggestions) : []
          );
      }

      debouncedCallback(inputValue, (i) => {
        const geocode = async () => {
          const mapboxResults = await searchMapBoxLocation(
            MAPBOX_TOKEN,
            i,
            undefined,
            { types: 'country,region,place,postcode', language: i18n.language }
          );
          if (active && mapboxResults)
            setOptions((o) => [
              ...o,
              ...(mapboxResults.features as Feature[]).slice(
                0,
                maxSuggestions - o.length
              ),
            ]);
        };
        geocode();
      });
    } else if (inputValue.length === 0) {
      setOptions([]);
    } else {
      setOptions([]);
    }

    return () => {
      active = false;
    };
  }, [inputValue, debouncedCallback, sportsLocations, i18n, onRegionSelected]);

  React.useEffect(() => {
    if (searchText) setInputValue(`${decodeURIComponent(searchText)}`);
    if (facility) setInputValue(`${decodeURIComponent(facility)}`);
  }, [facility, searchText]);

  React.useEffect(() => {
    if (facility === undefined && searchText === undefined) {
      setInputValue('');
    }
  }, [facility, searchText]);

  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return !props.mobileButton && mobileScreen ? null : (
    <Autocomplete
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (inputValue === '' || inputValue.length <= 2) {
            mapRef.current?.flyTo(defaultLatLngZoom);
          }
          const pressedOption =
            e.currentTarget.getElementsByTagName('input')[0].value;
          handleAutocompleteChange(
            options.filter((e) => e.place_name === pressedOption)[0] ?? null
          );
        }
      }}
      id="smartracks-geoCoder"
      autoHighlight
      autoSave="false"
      autoComplete
      includeInputInList
      filterSelectedOptions
      clearIcon={
        <CloseIcon
          visibility={'visible'}
          sx={{ display: ['none', 'flex'] }}
          onClick={() => setFacility(undefined)}
        />
      }
      freeSolo
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.place_name
      }
      options={options}
      filterOptions={(options, inputValue) => options}
      value={value}
      inputValue={inputValue}
      clearOnBlur={false}
      onChange={(event, newValue, reason) => {
        if (typeof newValue !== 'string') {
          if (reason === 'clear') handleAutocompleteChange(null);
          handleAutocompleteChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') handleAutocompleteChange(null);
        setInputValue(
          newInputValue.replace('\u{1F4CD}', '').replace('\u{1F3C3}', '')
        );
        props.inputSet(newInputValue);
      }}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, inputProps, ...rest } = params;
        return (
          <Paper
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: ['100%', 335],
              height: 40,
              p: [theme.spacing(0), theme.spacing(1)],
            }}
          >
            <IconButton
              id="search-icon-container"
              type="reset"
              aria-label="search"
              onClick={() => handleAutocompleteChange(options[0])}
            >
              <SearchIcon id="search-icon" />
            </IconButton>
            <InputBase
              {...InputProps}
              {...rest}
              inputProps={{
                ...inputProps,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    if (inputValue === '' || inputValue.length <= 2) {
                      mapRef.current?.flyTo(defaultLatLngZoom);
                    }
                    const pressedOption = e.currentTarget.value;

                    handleAutocompleteChange(
                      options.filter(
                        (e) => e.place_name === pressedOption
                      )[0] ?? null
                    );
                  }
                },
              }}
              fullWidth
              sx={{ pl: 1, width: '300px', borderColor: 'transparent' }}
              placeholder={placeholder}
              margin={'none'}
              color={'secondary'}
            />
            {mobileScreen && (
              <CloseIcon
                visibility={'visible'}
                sx={{ display: ['flex', null] }}
                onClick={() => {
                  handleAutocompleteChange(null);
                  setFacility(undefined);
                  if (props.inputCleanedCallback)
                    props.inputCleanedCallback(inputValue);
                }}
              />
            )}
          </Paper>
        );
      }}
      renderOption={(props, option) => {
        return (
          <Box {...props} component="li">
            <Typography variant="body2" color="text.secondary">
              {option.place_name}
            </Typography>
          </Box>
        );
      }}
    />
  );
};

export default SearchLocation;
