import { LayerProps } from 'react-map-gl';

const TRACKS_LAYER_ID = 'smartracks-locations';

const tracksClusterLayer: LayerProps = {
  id: 'clusters-st',
  type: 'symbol',
  source: TRACKS_LAYER_ID,
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12,
    'text-allow-overlap': true,
    'icon-image': [
      'case',
      ['==', ['get', 'point_count'], ['get', 'smartracksEnabledCount']],
      'smartracks-cluster-symbol',
      ['==', 0, ['get', 'smartracksEnabledCount']],
      'non-smartracks-cluster-symbol',
      'mixed-cluster-symbol',
    ],
    'icon-size': 0.85,
    'icon-allow-overlap': true,
    'icon-anchor': 'center',
  },
  paint: {
    'text-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#ffffff',
      '#000000',
    ],
  },
};

const tracksUnclusteredPointsLayer: LayerProps = {
  id: 'unclustered-point-st',
  type: 'symbol',
  source: TRACKS_LAYER_ID,
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': [
      'case',
      ['get', 'smarTracksEnabled'],
      'smartracks-marker',
      'non-smartracks-marker',
    ],

    'icon-size': 0.75,
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
  },
};

export { TRACKS_LAYER_ID, tracksClusterLayer, tracksUnclusteredPointsLayer };
