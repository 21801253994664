import React from 'react';
import SmartracksTechnologyLogo from '../../assets/smartracks-technology-logo.svg';

const Branding = () => {
  return (
    <img
      style={{
        cursor: 'pointer',
        marginBottom: '0px',
        marginLeft: '6px',zIndex:3,
    
      }}
      src={SmartracksTechnologyLogo}
      alt="SmarTracks Technology Logo"
      onClick={(_) => window.open('https://humotion.net/', '_blank')}
    />
  );
};

export default Branding;
