

export interface LatLngZoom {
  latitude: number;
  longitude: number;
  zoom: number;
}

export const toKebabCase = (name: string): string => {
  let nameWords = name.split(' ');
  let trimmedNameWords = Array<string>();
  // Remove all trailing and leading commas and dashes from each word
  nameWords.forEach((word) => {
    trimmedNameWords.push(word.replace(/^[,-]+|[,-]+$/g, ''));
  });
  return trimmedNameWords.join('-').toLowerCase();
};

export const validateLatLngZoomParam = (param: string): boolean => {
  let parts = param.split(',');
  if (parts.length !== 3) return false;
  if (!parts.every((p) => !isNaN(parseFloat(p)))) return false;
  if (parseFloat(parts[0]) < -90 || parseFloat(parts[0]) > 90) return false; // Latitude range
  if (parseFloat(parts[1]) < -180 || parseFloat(parts[1]) > 180) return false; // Longitude range
  if (parseFloat(parts[2]) < 0 || parseFloat(parts[2]) > 24) return false; // Mapbox zoom range
  return true;
};

/**
 * Parses a string containing latitude, longitude and zoom to its components
 * @inputString String containing latitude, longitude and zoom, e.g. 14.5923473,16.4609164,4.10z
 * @returns An object containing latitude, longitude and zoom level (can be undefined)
 */
export const parseLatLngZoomParam = (
  inputString: string,
  def: LatLngZoom
): LatLngZoom => {
  if (!validateLatLngZoomParam(inputString)) return def;
  else {
    const parts = inputString.split(',');
    return {
      latitude: parseFloat(parts[0]),
      longitude: parseFloat(parts[1]),
      zoom: parseFloat(parts[2]),
    };
  }
};

export const copyToClipboard = (text:string) => {
  const navigatorCopy = async (text1:string) => {
    try {
      await navigator.clipboard.writeText(
        text1
      );
    } catch (error) {
      console.log('Error copying guid', error);
    }
  };
  navigatorCopy(text);
};


/**
 *  Sorting Algo, checking if checked true so there is a swap in the iteration
 * this algo will only be run if array not sorted 
 * this been done by use do while and only if checked true is, avoiding running the whole thing more than once 
 * and only if its necessary
 * @param inputArr the Array to be sorted 
 * @returns sorted Array
 */
export const bubbleSortAlgo = (inputArr: number[]) => {
  let len = inputArr.length;
  let checked;
  do {
      checked = false;
      for (let i = 0; i < len; i++) {
          if (inputArr[i] > inputArr[i + 1]) {
              let tmp = inputArr[i];
              inputArr[i] = inputArr[i + 1];
              inputArr[i + 1] = tmp;
              checked = true;
          }
      }
  } while (checked);
  return inputArr;
};

//asserting two Arrays are equal
export function arraysEqual(a: any, b: any) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const sumArrayElements = (arrayToSum:number[])=>
arrayToSum.reduce((partialSum, a) => partialSum + a, 0);


export const extractDifferenceAbsolutOfTwoNumbers = (a:number,b:number)=>Math.abs(a-b);

export const extractArrayElementsDifference = (arrayToCalc:number[])=>{
  let sortedArray = bubbleSortAlgo(arrayToCalc);
  return arrayToCalc.map(
    (e,idx) => {
      if(idx+1 <= arrayToCalc.length-1){
       return (extractDifferenceAbsolutOfTwoNumbers(sortedArray[idx+1],e))}
      else{return 0}
     }
  );

}

export const sumArrayElementsTillMaximal = (elementsArray : number[], maxDistance:number) => {
  let accumulator = 0;
  let finalArray :number[]=[0];
   elementsArray.forEach((e,idx)=>{  
      if(e !==0){
           accumulator += e;
           finalArray.push(accumulator); 
        }
        if(e === 0){
            if(finalArray[finalArray.length-1] !== maxDistance && maxDistance !== 0 && extractDifferenceAbsolutOfTwoNumbers(
              finalArray[finalArray.length-1],maxDistance)>=5 )
              finalArray.push(maxDistance);
        }
  });
  return finalArray;
}