import React from 'react';
import { Container, Paper, Typography, Box, Link } from '@mui/material';

export type GenericErrorProps = {
  retrying: boolean;
};

const GenericError = (props: GenericErrorProps) => {
  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Paper>
        <Box p={4}>
          <Typography variant="h4" color="primary">
            Something went wrong :({' '}
          </Typography>
          {props.retrying && (
            <Typography>
              Retrying to fetch the SmarTracks sports locations..
            </Typography>
          )}
          {!props.retrying && (
            <Typography>
              Please try again later. In the meantime, visit{' '}
              <Link target="blank" href="https://smartracks.run/">
                smartracks.run
              </Link>{' '}
              and{' '}
              <Link target="blank" href="https://humotion.net/en/home/">
                humotion.net
              </Link>{' '}
              for more information.
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default GenericError;
