import React, { useRef } from 'react';
import { useMap } from 'react-map-gl';
import { Box } from '@mui/material';

import {
  SportsLocation,
  getSportsLocationSvgGeorectangle,
  getSportsLocationSvgUrl,
  SportsLocationGeorectangle,
} from '../../services/SportsLocation/SportsLocation.service';

import SvgOverlay from './SvgOverlay';

type OverlayLayerProps = {
  sportsLocations: SportsLocation[];
  currentMapZoom: number;
  visibleAtZoom: number;
};

type LoadState = {
  guid: string;
  loadError: boolean;
  svgSrcUrl: string;
  georectangle?: SportsLocationGeorectangle;
};

const SportsLocationOverlayLayer = (props: OverlayLayerProps) => {
  const { sportsLocations, currentMapZoom, visibleAtZoom } = props;
  const loadState = React.useMemo(() => new Map<string, LoadState>(), []);

  const [renderableLocations, setRenderableLocations] = React.useState(
    new Map(
      Array.from(loadState).filter(
        ([guid, state]) => !state.loadError && state.svgSrcUrl !== ''
      )
    )
  );

  React.useEffect(() => {
    if (currentMapZoom < visibleAtZoom || sportsLocations.length === 0) return;

    const getRenderData = (guid: string) => {
      return getSportsLocationSvgGeorectangle(guid, 'NoText');
    };

    sportsLocations
      .filter((l) => !loadState.has(l.sportsLocationGUID))
      .map((l) =>
        getRenderData(l.sportsLocationGUID)
          .then((georectangle) => {
            const newState = {
              guid: l.sportsLocationGUID,
              svgSrcUrl: getSportsLocationSvgUrl(
                l.sportsLocationGUID,
                'NoText'
              ),
              georectangle: georectangle,
              loadError: false,
            };
            loadState.set(l.sportsLocationGUID, newState);
            setRenderableLocations(
              new Map(
                Array.from(loadState).filter(
                  ([guid, state]) => !state.loadError && state.svgSrcUrl !== ''
                )
              )
            );
          })
          .catch((e) => {
            loadState.set(l.sportsLocationGUID, {
              guid: l.sportsLocationGUID,
              svgSrcUrl: '',
              loadError: true,
            });
            console.log('Error while getting sports location svgs ', e);
          })
      );
  }, [currentMapZoom, visibleAtZoom, sportsLocations, loadState]);

  return currentMapZoom >= visibleAtZoom && renderableLocations.size > 0 ? (
    <Box>
      {Array.from(renderableLocations)
        .filter(([guid, l]) => !l.loadError)
        .map(([guid, l]) =>
          l.georectangle ? (
            <SvgOverlay key={guid}>
              <SportsLocationSvg
                svgAltText={l.guid}
                svgSrcUrl={l.svgSrcUrl}
                northWestCoordinates={[
                  l.georectangle.northWest.longitude,
                  l.georectangle.northWest.latitude,
                ]}
                northEastCoordinates={[
                  l.georectangle.northEast.longitude,
                  l.georectangle.northEast.latitude,
                ]}
                southWestCoordinates={[
                  l.georectangle.southWest.longitude,
                  l.georectangle.southWest.latitude,
                ]}
              />
            </SvgOverlay>
          ) : null
        )}
    </Box>
  ) : null;
};

type SportsLocationSvgProps = {
  svgAltText: string;
  svgSrcUrl: string;
  northWestCoordinates: [number, number];
  northEastCoordinates: [number, number];
  southWestCoordinates: [number, number];
};

const SportsLocationSvg = ({
  svgAltText,
  svgSrcUrl,
  northWestCoordinates,
  northEastCoordinates,
  southWestCoordinates,
}: SportsLocationSvgProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const mapRef = useMap();
  const map = mapRef.current;

  const calculateStyles = () => {
    if (!map || !imgRef.current) return null;

    // Project control points to container-pixel coordinates
    const pxTopLeft = map.project(northWestCoordinates);
    const pxTopRight = map.project(northEastCoordinates);
    const pxBottomLeft = map.project(southWestCoordinates);
    const pxBottomRight = map.project(
      map.unproject([
        pxTopRight.x - pxTopLeft.x + pxBottomLeft.x,
        pxTopRight.y - pxTopLeft.y + pxBottomLeft.y,
      ])
    );

    const pxMin = [
      Math.min(pxTopLeft.x, pxTopRight.x, pxBottomLeft.x, pxBottomRight.x),
      Math.min(pxTopLeft.y, pxTopRight.y, pxBottomLeft.y, pxBottomRight.y),
    ];

    const pxMax = [
      Math.max(pxTopLeft.x, pxTopRight.x, pxBottomLeft.x, pxBottomRight.x),
      Math.max(pxTopLeft.y, pxTopRight.y, pxBottomLeft.y, pxBottomRight.y),
    ];

    const pxBoundsSize = [pxMax[0] - pxMin[0], pxMax[1] - pxMin[1]];
    const pxTopLeftInDiv = [pxTopLeft.x - pxMin[0], pxTopLeft.y - pxMin[1]];

    // Sides of the control-point box, in pixels
    // These are the main ingredient for the transformation matrix.
    const vectorX = [pxTopRight.x - pxTopLeft.x, pxTopRight.y - pxTopLeft.y];
    const vectorY = [
      pxBottomLeft.x - pxTopLeft.x,
      pxBottomLeft.y - pxTopLeft.y,
    ];

    /**
     * The transformation is an affine matrix that switches
     * coordinates around in just the right way. This is the result
     * of calculating the skew/rotation/scale matrices and simplyfing
     * everything. */
    return {
      containerLeft: `${pxMin[0]}px`,
      containerTop: `${pxMin[1]}px`,
      containerHeight: `${pxBoundsSize[1]}px`,
      containerWidth: `${pxBoundsSize[0]}px`,
      imgTransform: {
        transformOrigin: '0 0',
        transform: `matrix(${vectorX[0] / imgRef.current.width}, ${
          vectorX[1] / imgRef.current.width
        }, ${vectorY[0] / imgRef.current.height},${
          vectorY[1] / imgRef.current.height
        }, ${pxTopLeftInDiv[0]}, ${pxTopLeftInDiv[1]})`,
      },
    };
  };

  const styles = calculateStyles();
  const [, setVersion] = React.useState(0);

  return styles ? (
    <div
      ref={containerRef}
      style={{
        position: 'absolute',
        left: styles.containerLeft,
        top: styles.containerTop,
        height: styles.containerHeight,
        width: styles.containerWidth,
      }}
    >
      <img
        ref={imgRef}
        src={svgSrcUrl}
        style={styles.imgTransform}
        alt={svgAltText}
        onLoad={() => setVersion((x) => x + 1)}
      />
    </div>
  ) : (
    <div ref={containerRef}>
      <img
        ref={imgRef}
        src={svgSrcUrl}
        alt={svgAltText}
        onLoad={() => setVersion((x) => x + 1)}
      />
    </div>
  );
};

export default SportsLocationOverlayLayer;
