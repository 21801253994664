import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { JSONDecodeError } from '../errors';
import ApiConfiguration from '../apiConfiguration';
import { throwIfResponseError } from '../shared';
import Format from 'string-format';
import i18next from 'i18next';

const stringOrNull = t.union([t.string, t.null]);
const boolOrNull = t.union([t.boolean, t.null]);

const position = t.type({
  latitude: t.number,
  longitude: t.number,
  heightAboveSea: t.number,
});

const country = t.type({
  name: t.string,
  phoneCode: t.string,
});

const address = t.type({
  country: country,
  state: stringOrNull,
  district: stringOrNull,
  postalCode: stringOrNull,
  city: stringOrNull,
  streetAndNumber: stringOrNull,
});

const name = t.type({
  formalName: stringOrNull,
  shortName: stringOrNull,
  nickName: stringOrNull,
});

const georectangle = t.type({
  northEast: position,
  northWest: position,
  southEast: position,
  southWest: position,
});

const svg = t.type({
  name: t.string,
  link: t.string,
  geoRectangle: t.union([georectangle, t.null]),
});
const timingGate = t.type({
  timingGateID: t.number,
  itemAtInMeter: t.number,
  itemAtNominalInMeter: t.number,
  //displayedDistanceInMeters: t.number,
});
const lane = t.type({
  id: t.number,
  laneNumberFromLeft: t.number,
  nominalLaneLengthInMeter: t.union([t.number, t.null]),
  realLengthInMeter: t.number,
  smarTracksEnabled: boolOrNull,
  timingGates: t.array(timingGate),
});

const functionalArea = t.type({
  areaType: t.string,
  smarTracksEnabled: boolOrNull,
  lanes: t.union([t.array(lane), t.undefined]),
  surfaceType: t.string,
  shortName: t.union([t.undefined, stringOrNull]),
});

const sportsLocationOptional = t.partial({
  schemaVersion: stringOrNull,
  openTrackGuid: stringOrNull,
  locationInformationIsPublic: stringOrNull,
  locationIsAccessibleToPublic: stringOrNull,
  hasPositiveGateSignal: boolOrNull,
  isIndoor: boolOrNull,
  facilityLogo: stringOrNull,
  facilityTitleLine1: stringOrNull,
  facilityTitleLine2: stringOrNull,
  facilityTitleLine3: stringOrNull,
  promotionLine: stringOrNull,
  areaTypes: t.array(t.string),
  functionalAreas: t.array(functionalArea),
  anchorType: t.number,
  anchorFunctionalAreaID: t.number,
  anchorFunctionalAreaLeftmostLaneLeftFinish: t.union([position, t.null]),
  anchorFunctionalAreaRightmostLaneRightFinish: t.union([position, t.null]),
  svGs: t.array(svg),
  smarTracksEnabled: boolOrNull,
  originalConstructionDate: stringOrNull,
});

const sportsLocationRequired = t.type({
  sportsLocationGUID: t.string,
  anchorLocation: position,
  workingHours: t.array(t.string),
  availableSvgPaths: t.array(t.string),
  addresses: t.array(address),
  names: t.array(name),
});

const sportsLocation = t.intersection([
  sportsLocationOptional,
  sportsLocationRequired,
]);

const propertiesType = t.type({
  wikidata: t.string,
  short_code: t.string,
});
const geometry = t.type({
  type: t.string,
  coordinates: t.array(t.number),
});
const feature = t.type({
  id: t.string,
  type: t.string,
  place_type: t.array(t.string),
  relevance: t.number,
  properties: propertiesType,
  text: t.string,
  place_name: t.string,
  bbox: t.array(t.number),
  center: t.array(t.number),
  geometry: geometry,
});
const geoCoderResponse = t.type({
  type: t.string,
  query: t.array(t.string),
  features: t.array(feature),
  attribution: t.string,
});

export interface Feature {
  id: string;
  type: string;
  place_name: string;
  place_type: string[];
  center: number[];
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties: {
    smartracks: boolean;
  };
  bbox?: number[];
}

export interface MapBoxData {
  type: string;
  query: string[];
  features: Feature[];
  attribution: string;
}
const exactGeoCoderResponse = t.exact(geoCoderResponse);
export type LocationGeoCoderResponse = t.TypeOf<typeof exactGeoCoderResponse>;

const exactSportsLocation = t.exact(sportsLocation);

const sportsLocations = t.array(exactSportsLocation);

export type SportsLocation = t.TypeOf<typeof exactSportsLocation>;

export type SportsLocationGeorectangle = t.TypeOf<typeof georectangle>;

export const getSportsLocations = async (
  options?: RequestInit
): Promise<SportsLocation[]> => {
  const response = await fetch(
    ApiConfiguration.GET_SPORTS_LOCATIONS_URL,
    options
  );
  throwIfResponseError(response);
  const jsonResponse = await response.json();
  const result = sportsLocations.decode(jsonResponse);
  if (!isRight(result)) throw new JSONDecodeError();
  return result.right;
};

export const getSportsLocation = async (
  guid: string,
  options?: RequestInit
): Promise<SportsLocation> => {
  const response = await fetch(
    Format(ApiConfiguration.GET_SPORTS_LOCATION_DETAILS_URL, guid),
    options
  );
  throwIfResponseError(response);
  const jsonResponse = await response.json();
  const result = sportsLocation.decode(jsonResponse);
  if (!isRight(result)) throw new JSONDecodeError();
  return result.right;
};

export const getSportsLocationSvgGeorectangle = async (
  guid: string,
  svgName: string,
  options?: RequestInit
): Promise<SportsLocationGeorectangle> => {
  const response = await fetch(
    Format(
      ApiConfiguration.GET_SPORTS_LOCATION_SVG_GEORECTANGLE_URL,
      guid,
      svgName
    ),
    options
  );
  throwIfResponseError(response);
  const jsonResponse = await response.json();
  const result = georectangle.decode(jsonResponse);
  if (!isRight(result)) throw new JSONDecodeError();
  return result.right;
};

export const getSportsLocationSvgString = async (
  guid: string,
  svgName: string,
  options?: RequestInit
): Promise<string> => {
  const response = await fetch(
    Format(ApiConfiguration.GET_SPORTS_LOCATION_SVG_URL, guid, svgName),
    options
  );
  throwIfResponseError(response);
  const xmlResponse = await response.text();
  return xmlResponse;
};

export const getSportsLocationSvgUrl = (
  guid: string,
  svgName: string
): string =>
  Format(ApiConfiguration.GET_SPORTS_LOCATION_SVG_URL, guid, svgName);

export const getSearchedPlaceCode = async (
  search_text: string,
  options?: RequestInit
): Promise<LocationGeoCoderResponse> => {
  const response = await fetch(
    Format(
      ApiConfiguration.MAPBOX_DATA_OF_SINGLE_LOCATION,
      search_text,
      i18next.language
    ),
    options
  );
  throwIfResponseError(response);
  const jsonResponse = await response.json();
  return jsonResponse;
};
