import { createTheme, adaptV4Theme } from '@mui/material/styles';

const smarTracksMapTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: '#F49C08' },
      secondary: { main: '#FFFFFF' },
      error: { main: '#FA5E05' },
    },
    typography: {
      fontFamily: ['Ubuntu', 'sans-serif'].join(','),
      fontSize: 13,
    },
    shape: {
      borderRadius: 2,
    },
    spacing: 5,
    props: {
      MuiList: {
        dense: true,
      },
      MuiMenuItem: {
        dense: true,
      },
      MuiTable: {
        size: 'small',
      },
      MuiButton: {
        size: 'small',
      },
      MuiButtonGroup: {
        size: 'small',
      },
      MuiCheckbox: {
        size: 'small',
      },
      MuiFab: {
        size: 'small',
      },
      MuiFormControl: {
        margin: 'dense',
        size: 'small',
      },
      MuiFormHelperText: {
        margin: 'dense',
      },
      MuiIconButton: {
        size: 'small',
      },
      MuiInputBase: {
        margin: 'dense',
      },
      MuiInputLabel: {
        margin: 'dense',
      },
      MuiRadio: {
        size: 'small',
      },
      MuiSwitch: {
        size: 'small',
      },
      MuiTextField: {
        margin: 'dense',
        size: 'small',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 540,
        md: 768,
        lg: 1280,
        xl: 1920,
      },
    },
  })
);

export default smarTracksMapTheme;
