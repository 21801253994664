export default class ApiConfiguration {
  // Mapbox configuration
  public static readonly MAPBOX_TOKEN: string =
    'pk.eyJ1IjoiaHVtb3Rpb24iLCJhIjoiY2pwOGphaXViMDNvZzN1c2I1b21pMGl2NyJ9.hp_iM9I-sNJ6Rj792O1Aew';
  public static readonly MAPBOX_URL: string =
    'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
    public static readonly MAPBOX_DATA_OF_SINGLE_LOCATION: string =
    'https://api.mapbox.com/geocoding/v5/mapbox.places/{0}.json?access_token=pk.eyJ1IjoiaHVtb3Rpb24iLCJhIjoiY2pwOGphaXViMDNvZzN1c2I1b21pMGl2NyJ9.hp_iM9I-sNJ6Rj792O1Aew&language={1}'
  // Humotion Api configuration
  private static readonly HOST: string =
    process.env.REACT_APP_API || 'https://apird.medjv.net/';
  public static readonly GET_SPORTS_LOCATIONS_URL: string =
    ApiConfiguration.HOST +
    'v1/trackdb/sportslocations?page=1&pagesize=999&includeNonSmarTracks=true';
  public static readonly GET_SPORTS_LOCATION_DETAILS_URL: string =
    ApiConfiguration.HOST + 'v1/trackdb/sportslocations/{0}';
  public static readonly GET_SPORTS_LOCATION_SVG_URL: string =
    ApiConfiguration.HOST + 'v1/trackdb/sportslocations/{0}/svgs/{1}';
  public static readonly GET_SPORTS_LOCATION_SVG_GEORECTANGLE_URL: string =
    ApiConfiguration.HOST +
    'v1/trackdb/sportslocations/{0}/svgs/{1}/georectangle';
}
