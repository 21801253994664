import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { useQueryParam } from 'use-query-params';
import { useTheme } from '@mui/material/styles';
import { FilterProps } from '../../services/Filters/Filters.service';

interface RadioFilterProps {
  defaultValue: string;
  values: string[];
  keys: string[];
  counts?: number[];
}

const RadioFilter = (
  props: RadioFilterProps &
    FilterProps<string | null | undefined, string | null | undefined>
) => {
  const { defaultValue, values, keys, queryParam, queryParamType, counts } =
    props;
  const [value, setValue] = useQueryParam(queryParam, queryParamType);

  const theme = useTheme();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const idx = keys.indexOf(value);
    if (values[idx] === defaultValue) setValue(undefined);
    else setValue(value);
  };

  return (
    <RadioGroup
      value={value !== undefined ? value : keys[values.indexOf(defaultValue)]}
      onChange={handleChange}
    >
      {values.map((v, idx) => (
        <FormControlLabel
          key={keys[idx]}
          disabled={counts === undefined ? true : counts[idx] === 0}
          sx={{ marginY: theme.spacing(-0.5), flexGrow: 0 }}
          control={
            <Radio
              color="primary"
              sx={{ color: '#F49C08', paddingY: theme.spacing(1.5) }}
            />
          }
          value={keys[idx]}
          label={`${v} [${counts === undefined ? 0 : counts[idx]}]`}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioFilter;
