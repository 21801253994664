import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['de', 'en', 'fr', 'pt', 'it', 'hu', 'es'];

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: 'en',
    debug: true,
    supportedLngs: languages,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `${process.env.PUBLIC_URL}/locales/{{ns}}.{{lng}}.json`,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
