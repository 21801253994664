import * as React from 'react';
import { Box, Grid, Tooltip, Typography, Card } from '@mui/material';
import { t } from 'i18next';
import theme from '../../utils/theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface FloatingCounterProps {
  totalLocationsNumber?: number;
  totalSmartracksLocationsNumber?: number;
  hGrade: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fontWeight: number;
}

const FloatingCounter = (props: FloatingCounterProps) => {
  return (
    <Card
      sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
    >
      <Grid container direction={'row'} p={1}>
        <Grid item>
          <Typography
            variant={props.hGrade}
            fontWeight={props.fontWeight}
            sx={{
              color: '#000000',
            }}
          >
            {props.totalLocationsNumber ?? -1 > 0
              ? props.totalLocationsNumber
              : '...'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={props.hGrade} textAlign="center">
            |
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Typography
            variant={props.hGrade}
            color="primary"
            fontWeight={props.fontWeight}
          >
            {props.totalSmartracksLocationsNumber ?? -1 > 0
              ? props.totalSmartracksLocationsNumber
              : '...'}
          </Typography>
        </Grid>
        <Grid item xs="auto" pl={1}>
          <Box sx={{ paddingTop: theme.spacing(1.5) }}>
            <Tooltip disableFocusListener title={`${t('counterBtn')}`}>
              <InfoOutlinedIcon
                sx={{
                  fontSize: '15px',
                  paddingLeft: theme.spacing(0.5),
                  color: '#000000',
                }}
              />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default FloatingCounter;
