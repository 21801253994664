import { HTTPError } from './errors';

export const throwIfResponseError = (
  response: Response,
  message?: string | undefined
) => {
  if (!response.ok) {
    throw new HTTPError(response.status, message);
  }
};
