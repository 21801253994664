import { QueryParamConfig } from 'use-query-params';
import { SportsLocation } from '../SportsLocation/SportsLocation.service';
import countries from 'i18n-iso-countries';

import english from 'i18n-iso-countries/langs/en.json';
import deutsch from 'i18n-iso-countries/langs/de.json';
import french from 'i18n-iso-countries/langs/fr.json';
import spainish from 'i18n-iso-countries/langs/es.json';
import hungarian from 'i18n-iso-countries/langs/hu.json';
import italien from 'i18n-iso-countries/langs/it.json';
import portoguese from 'i18n-iso-countries/langs/pt.json';

export const QUERY_PARAM_SEARCH = 'search';
export const QUERY_PARAM_FACILITY = 'facility';
export const QUERY_PARAM_SMARTRACKS_ENABLED = 'smart';

[french, english, deutsch, spainish, hungarian, italien, portoguese].forEach(
  (l) => countries.registerLocale(l)
);

export const filterSmarTracksEnabled = (
  value: boolean | null | undefined,
  location: SportsLocation
) => {

  // If no filter value is set, all tracks satisfy this condition
  if (value === undefined) return true;
  if (value === false) return true;
  else return location.smarTracksEnabled === value;
};

export const QUERY_PARAM_INDOOR_OUTDOOR = 'type';
export const filterIndoorOutdoor = (
  value: string | null | undefined,
  location: SportsLocation
) => {
  // If no filter value is set or the "all" filter is used (=>'*'), all tracks satisfy this condition
  if (value === undefined || value === '*') return true;
  if (value === 'indoor') return location.isIndoor === true;
  // locations where the isIndoor property is not set are assumed to be outdoors
  else
    return (
      location.isIndoor === false ||
      location.isIndoor === null ||
      location.isIndoor === undefined
    );
};

export const QUERY_PARAM_AREA_TYPE = 'functionalAreas';
export const FILTERABLE_FUNCTIONAL_AREAS = [
  'roundtrack',
  'linearsprint',
  'longjump',
  'triplejump',
  'polevault',
  'steeplechase',
];
export const QUERY_PARAM_AGILITY = 'agility';
export const QUERY_PARAM_FILTER_APPLIED= 'filtered';
export const FILTERABLE_AGILITY_AREAS = [
  'illinois',
  'arrowhead',
  '5-10-5',
  'threeconedrill',
  'custom',
];

export const filterFunctionalAreas = (
  value: string,
  location: SportsLocation
) => {
  switch (value) {
    case 'roundtrack':
      return (
        location.functionalAreas?.find(
          (fa) => fa.areaType === 'RoundTrackArea'
        ) !== undefined ?? false
      );
    case 'linearsprint':
      return (
        location.functionalAreas?.find((fa) => fa.areaType === 'SprintArea') !==
          undefined ?? false
      );
    case 'longjump':
      return (
        location.functionalAreas?.find(
          (fa) => fa.areaType === 'LongJumpArea'
        ) !== undefined ?? false
      );
    case 'triplejump':
      return (
        location.functionalAreas?.find((a) => a.areaType === 'TripleJump') !==
          undefined ?? false
      );
    case 'polevault':
      return (
        location.functionalAreas?.find((a) => a.areaType === 'PoleVault') !==
          undefined ?? false
      );
    case 'steeplechase':
      return (
        location.functionalAreas?.find((a) => a.areaType === 'Steeplechase') !==
          undefined ?? false
      );
    case QUERY_PARAM_AGILITY:
      return (
        FILTERABLE_AGILITY_AREAS.find((agilityArea) =>
          filterAgilityTests(agilityArea, location)
        ) !== undefined
      );
    default:
      return false;
  }
};
export const filterAgilityTests = (v: string, location: SportsLocation) => {
  switch (v) {
    case '5-10-5':
      return (
        location.functionalAreas?.find(
          (a) => a.areaType === 'FiveTenFive'
        ) !== undefined ?? false
      );
    case 'arrowhead':
      return (
        location.functionalAreas?.find(
          (a) => a.areaType === 'Arrowhead'
        ) !== undefined ?? false
      );
    case 'illinois':
      return (
        location.functionalAreas?.find((a) => a.areaType === 'IAT') !==
          undefined ?? false
      );
    case 'threeconedrill':
      return (
        location.functionalAreas?.find(
          (a) => a.areaType === 'ThreeConeDrill'
        ) !== undefined ?? false
      );
    case 'custom':
      return (
        location.functionalAreas?.find(
          (a) => a.areaType === 'CustomAgility'
        ) !== undefined ?? false
      );
    default:
      return false;
  }
};
export const QUERY_PARAM_SURFACE_TYPE = 'surfaceType';
export const FILTERABLE_SURFACE_TYPES = [`turf`, `synthetic`];
export const filterSurfaceType = (value: string, location: SportsLocation) => {
  switch (value) {
    case `synthetic`:
      return location.functionalAreas?.find(
        (st) => st.surfaceType === 'Synthetic'
      ) !== undefined
        ? true
        : false;
    case `turf`:
      return location.functionalAreas?.find(
        (st) => st.surfaceType === 'Turf'
      ) !== undefined
        ? true
        : false;
    default:
      return false;
  }
};
export const filterRegion = (
  value: { boundingBox?: number[]; countryPhoneCode?: string },
  location: SportsLocation,
  countryCode: string
) => {
  if (value.countryPhoneCode) {
    const countryEngName = countries.getName(countryCode, 'en');
    console.log(countryEngName)
    if (
      parseInt(location.addresses[0].country.phoneCode, 10) !==
        parseInt(value.countryPhoneCode.split(':')[1] ?? '') &&
      countryEngName === location.addresses[0].country.name
    ) {
      location.addresses[0].country.phoneCode =
        value.countryPhoneCode?.split(':')[1];
    }

    return (
      location.addresses.find(
        // parseInt removes leading zeros using radix 10
        (address) =>
          parseInt(address.country.phoneCode, 10) ===
          parseInt(value.countryPhoneCode?.split(':')[1] ?? '')
      ) !== undefined
    );
  } else if (value.boundingBox) {
    const minLng = value.boundingBox[0];
    const minLat = value.boundingBox[1];
    const maxLng = value.boundingBox[2];
    const maxLat = value.boundingBox[3];
    return (
      location.anchorLocation.longitude >= minLng &&
      location.anchorLocation.longitude <= maxLng &&
      location.anchorLocation.latitude >= minLat &&
      location.anchorLocation.latitude <= maxLat
    );
  } else {
    return true;
  }
};

export interface FilterProps<
  T extends boolean | string | (string | null)[] | undefined | null,
  D,
  D2 = D
> {
  queryParam: string;
  queryParamType: QueryParamConfig<D, D2>;
  sportsLocations?: SportsLocation[];
  evaluator: (value: T, location: SportsLocation) => boolean;
}
