import React, { Suspense, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Location,
} from 'react-router-dom';
import useSWR from 'swr';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import smarTracksMapTheme from './utils/theme';
import Root from './components/Root/Root';
import GenericError from './components/GenericError/GenericError';
import ApiConfig from './services/apiConfiguration';
import { getSportsLocations } from './services/SportsLocation/SportsLocation.service';
import { QueryParamProvider } from 'use-query-params';

import './MapStyleOverrides.css';

function App() {
  const [retryCount, setRetryCount] = React.useState<number>(0);

  /**
   * Using stale-while-revalidate to always provide the application
   * with the latest of all sports locations.
   * This respects http caching of locations on the api side.
   */
  const { data, error } = useSWR(
    ApiConfig.GET_SPORTS_LOCATIONS_URL,
    () => getSportsLocations(),
    {
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        console.log(key, option);
        if (error) console.log(error);
        if (!retryCount) return;
        setRetryCount(retryCount);
        if (retryCount >= 3) return;
        if (error.status === 404) return;

        // retry after 5 seconds
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={smarTracksMapTheme}>
        <Suspense fallback={<div>Loading...</div>}>
          {!error && (
            <Router basename={process.env.PUBLIC_URL}>
              <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                <Routes>
                  <Route path="*" element={<Root data={data} />} />
                  <Route path="*/@:latLngZoom" element={<Root data={data} />} />
                </Routes>
              </QueryParamProvider>
            </Router>
          )}
          {!data && error && <GenericError retrying={retryCount < 3} />}
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const RouteAdapter: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      // can disable eslint for parts here, location.state can be anything
      replace(location: Location) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, {
          replace: false,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          state: location.state,
        });
      },
    }),
    [navigate]
  );
  // https://github.com/pbeshai/use-query-params/issues/196
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return children({ history: adaptedHistory, location });
};

export default App;
