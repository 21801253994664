import React from 'react';
import { Box, Stack, Menu } from '@mui/material';

import theme from '../../utils/theme';

type PopUpMenuProps = {
  menuIcon: React.ReactNode;
  children?: React.ReactNode;
  Styles?: string;
};

const PopUpMenu = (props: PopUpMenuProps) => {
  const { menuIcon, children, Styles} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event: any) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(anchorEl === null ? event.currentTarget : null);
    }
  };

  return (
    <Box
      sx={{
        width: '29px',
        height: '29px',
        backgroundColor:  '#f7f7f7',
        boxShadow: '0 0 0 2px rgb(0 0 0/10%)',
        borderRadius: theme.shape.borderRadius,
        cursor:"pointer",
        ':hover': { backgroundColor: "rgb(235, 235, 235)" },        
      }}
      onClick={handleOpen}
    >
      <Stack
        className={Styles}
        aria-controls="open-icon"
        aria-haspopup="true"
        id="menu-icon-div"
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="29px"
      >
        {menuIcon}
      </Stack>
      <Menu
        onClick={() => handleOpen}
        anchorEl={anchorEl}
        sx={{
          padding: theme.spacing(1),
          marginLeft: theme.spacing(1),
          marginTop: theme.spacing(1),
        }}
        id="customized-menu"
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default PopUpMenu;
