import React, { cloneElement, memo, useState } from 'react';
import { Map } from 'mapbox-gl';
import { IControl, MapboxMap, useControl } from 'react-map-gl';
import { createPortal } from 'react-dom';

class SvgOverlayControl implements IControl {
  private _map: MapboxMap | null = null;
  private _container?: HTMLDivElement;
  private _reset: () => void;

  constructor(reset: () => void) {
    this._reset = reset;
  }

  onAdd(map: Map): HTMLElement {
    this._map = map;
    map.on('move', this._reset);
    this._container = document.createElement('div');
    this._reset();
    return this._container;
  }

  onRemove(map: Map): void {
    this._container?.remove();
    this._map?.off('move', this._reset);
    this._map = null;
  }

  getDefaultPosition() {
    return 'top-left';
  }

  getMap() {
    return this._map;
  }

  getElement() {
    return this._container;
  }
}

const SvgOverlay = (props: { children: React.ReactElement }) => {
  const [, setVersion] = useState(0);
  const ctrl = useControl<SvgOverlayControl>(() => {
    // This hacks the memoization of the overlay to rerender
    // because of the state changing
    const forceUpdate = () => setVersion((version) => version + 1);
    return new SvgOverlayControl(forceUpdate);
  });

  const map = ctrl.getMap();
  const container = ctrl.getElement();

  return map && container
    ? createPortal(cloneElement(props.children), container)
    : null;
};

export default memo(SvgOverlay);
