import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Box } from '@mui/material';
import { QueryParamConfig, useQueryParam } from 'use-query-params';
import { useTheme } from '@mui/material/styles';
import { FilterProps } from '../../services/Filters/Filters.service';

type FlagsFilterProps = {
  flagNames: (string | FlagsFilterProps)[];
  labels: string[];
  defaultValues: boolean[];
  flagsChanged?: (flagsSet: boolean[]) => void;
} & FilterProps<string, (string | null)[] | null | undefined>;

const FlagsFilter = (props: FlagsFilterProps) => {
  const {
    flagNames: names,
    labels,
    defaultValues,
    queryParam,
    queryParamType,
    sportsLocations,
    evaluator,
  } = props;

  const [flags, setFlags] = useQueryParam(queryParam, queryParamType);
  const [count, setCount] = useState(Array<number>(names.length));
  const theme = useTheme();

  // Calculate the count per selectable option whenever any of the relevant props change
  React.useEffect(() => {
    setCount(
      names.map((name) => {
        return sportsLocations
          ? sportsLocations.filter((l) =>
              evaluator(
                typeof name === 'string' || name instanceof String
                  ? (name as string)
                  : name.queryParam,
                l
              )
            ).length
          : 0;
      })
    );
  }, [setCount, names, sportsLocations, evaluator, flags]);

  // Handle checking an individual checkbox by (un)setting the corresponding
  // value in the query parameter.
  const handleChecked = (name: string, checked: boolean) => {
    if (flags === null || flags === undefined) {
      setFlags([name]);
    } else {
      if (checked) {
        setFlags([...flags, name]);
      } else {
        const removeElementIndex = flags.indexOf(name);
        // Since splice modifies the array it is called on and you cannot
        // modify flags itself (its const), we copy it first before modifying
        // and setting the new value.
        let newFlags = flags;
        newFlags.splice(removeElementIndex, 1);
        setFlags(newFlags.length > 0 ? newFlags : undefined);
      }
    }
  };

  // Returns whether a certain name of an option is checked or not
  const isChecked = (name: String, idx: number): boolean => {
    return flags === null || flags === undefined
      ? defaultValues[idx]
      : flags.find((c) => c === name) !== undefined;
  };

  return (
    <Box>
      {names.map((flag, idx) => (
        <Box key={idx}>
          {/* If the flag is a simple string, render a checkbox and label for it */}
          {(typeof flag === 'string' || flag instanceof String) && (
            <FormControlLabel
              sx={{ flexGrow: 0, display: 'block' }}
              control={
                <Checkbox
                  size="small"
                  sx={{
                    color: '#F49C08',
                    marginY: theme.spacing(0),
                    paddingY: theme.spacing(1),
                  }}
                  checked={isChecked(flag, idx)}
                  onChange={(e, val) => handleChecked(flag as string, val)}
                />
              }
              value={flag}
              label={`${labels[idx]} [${count[idx]}]`}
              labelPlacement="end"
              disabled={count[idx] === 0}
            />
          )}
          {/* If the flag is not a string, a sublist needs to be rendered */}
          {typeof flag !== 'string' && !(flag instanceof String) && (
            <SubFlagsFilter
              label={`${labels[idx]} [${count[idx]}]`}
              disabled={count[idx] === 0}
              parentQueryParam={queryParam}
              parentQueryParamType={queryParamType}
              sublistProps={flag}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

type SubFlagsFilterProps = {
  label: string;
  disabled: boolean;
  parentQueryParam: string;
  parentQueryParamType: QueryParamConfig<(string | null)[] | null | undefined>;
  sublistProps: FlagsFilterProps;
};

const SubFlagsFilter = (props: SubFlagsFilterProps) => {
  const {
    label,
    disabled,
    parentQueryParam,
    parentQueryParamType,
    sublistProps,
  } = props;
  const theme = useTheme();
  const [parentParams, setParentParams] = useQueryParam(
    parentQueryParam,
    parentQueryParamType
  );
  const [childParams, setChildParams] = useQueryParam(
    sublistProps.queryParam,
    sublistProps.queryParamType
  );

  // Using this hook the parent query param is added/removed
  // whenever one or more of the child params are selected
  React.useEffect(() => {
    if (childParams === null || childParams === undefined) {
      if (parentParams === null || parentParams === undefined) return;

      // Remove the queryParam of the sublist from the parent list
      const removeElementIndex = parentParams.indexOf(sublistProps.queryParam);

      if (removeElementIndex >= 0) {
        // Since splice modifies the array it is called on and you cannot
        // modify flags itself (its const), we copy it first before modifying
        // and setting the new value.
        let newFlags = parentParams;
        newFlags.splice(removeElementIndex, 1);
        setParentParams(newFlags.length > 0 ? newFlags : undefined);
      }
    } else if (
      childParams.length === sublistProps.flagNames.length &&
      sublistProps.flagNames.every(
        (childProp) =>
          childParams.find((childParam) => childParam === childProp) !==
          undefined
      )
    ) {
      if (parentParams === null || parentParams === undefined)
        setParentParams([sublistProps.queryParam]);
      else if (
        parentParams.find((param) => param === sublistProps.queryParam) ===
        undefined
      )
        setParentParams([...parentParams, sublistProps.queryParam]);
    }
  }, [childParams, parentParams, setParentParams, sublistProps]);

  // Handle (un)checking the parent checkbox by (un)setting the corresponding
  // value in the query parameters.
  const handleCheck = (checked: boolean, indeterminate: boolean) => {
    if (checked && !indeterminate) {
      // Check all child params but only for the child params that
      // are not disabled aka the ones where the evaluator does not return 0.
      setChildParams(
        sublistProps.flagNames.filter((flagName) => {
          if (sublistProps.sportsLocations !== undefined)
            return (
              sublistProps.sportsLocations.filter((location) =>
                sublistProps.evaluator(
                  typeof flagName === 'string' || flagName instanceof String
                    ? (flagName as string)
                    : flagName.queryParam,
                  location
                )
              ).length > 0
            );
          else return false;
        }) as string[]
      );
    } else {
      // Uncheck all child params as well
      setChildParams(undefined);
    }
  };

  const isChecked = (): boolean => {
    return (
      childParams !== null &&
      childParams !== undefined &&
      childParams.length === sublistProps.flagNames.length &&
      sublistProps.flagNames.every(
        (childProp) =>
          childParams.find((childParam) => childParam === childProp) !==
          undefined
      )
    );
  };

  const isIndeterminate = (): boolean => {
    return (
      childParams !== null &&
      childParams !== undefined &&
      childParams.length !== sublistProps.flagNames.length &&
      !sublistProps.flagNames.every(
        (childProp) =>
          childParams.find((childParam) => childParam === childProp) !==
          undefined
      )
    );
  };

  return (
    <Box>
      <FormControlLabel
        sx={{ flexGrow: 0, display: 'block' }}
        control={
          <Checkbox
            size="small"
            sx={{
              color: '#F49C08',
              marginY: theme.spacing(0),
              paddingY: theme.spacing(1),
            }}
            indeterminate={isIndeterminate()}
            checked={isChecked()}
            onChange={(e, val) => handleCheck(val, isIndeterminate())}
          />
        }
        value={sublistProps.queryParam}
        label={label}
        labelPlacement="end"
        disabled={disabled}
      />
      <Box sx={{ paddingLeft: theme.spacing(3) }}>
        <FlagsFilter {...sublistProps} />
      </Box>
    </Box>
  );
};

export default FlagsFilter;
