import React from 'react';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { MapRef } from 'react-map-gl';
import countryCodesList, { CountryProperty } from 'country-codes-list';
import {
  BooleanParam,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';

import AppContent from '../AppContent/AppContent';
import Branding from '../Branding/Branding';
import Map from '../Map/Map';
import {
  getSearchedPlaceCode,
  SportsLocation,
  getSportsLocationSvgGeorectangle,
  Feature,
} from '../../services/SportsLocation/SportsLocation.service';
import {
  filterSmarTracksEnabled,
  filterIndoorOutdoor,
  filterFunctionalAreas,
  filterRegion,
  QUERY_PARAM_AREA_TYPE,
  QUERY_PARAM_INDOOR_OUTDOOR,
  QUERY_PARAM_SMARTRACKS_ENABLED,
  QUERY_PARAM_SURFACE_TYPE,
  filterSurfaceType,
  QUERY_PARAM_AGILITY,
  filterAgilityTests,
} from '../../services/Filters/Filters.service';
import WebMercatorViewport from 'viewport-mercator-project';
import { LatLngZoom } from '../../utils/utils';
import { useSearchParams } from 'react-router-dom';

type RootProps = {
  data?: SportsLocation[];
  indoorOutdoorCounts?: number[];
};

const Root = (props: RootProps) => {
  const { data } = { ...props };
  const mapRef = React.useRef<MapRef>(null);
  const [mapStyle = 'outdoors-v11'] = useQueryParam('mapStyle', StringParam);
  const [searchedText] = useQueryParam('search', StringParam);

  const countriesCodes = countryCodesList.customList(
    'countryCode' as CountryProperty,
    '{countryNameEn}: {countryCallingCode}'
  );
  const [facility, setSmartracksLocationName] = useQueryParam(
    'facility',
    StringParam
  );
  const [searchText] = useQueryParam('search', StringParam);
  /**
   * The region filter either acts on the bounding box (number[])
   * or the country phone code (string). It can also be undefined
   * if no filter is set.
   **/
  const [regionFilter, setRegionFilter] = React.useState<
    number[] | string | undefined
  >(undefined);
  const [countryCode, setCountryCode] = React.useState('');

  const handleRegionFilterChange = (
    regionFilterParam: string | number[] | undefined
  ) => {
    if (facility) {
      setSmartracksLocationName(undefined);
    }
    if (
      typeof regionFilterParam === 'string' &&
      regionFilterParam !== undefined
    ) {
      const result =
        countriesCodes[regionFilterParam.toUpperCase() as CountryProperty];
      console.log(countriesCodes);
      setCountryCode(regionFilterParam);
      setRegionFilter(result);
    } else {
      setRegionFilter(regionFilterParam);
    }
  };

  React.useEffect(() => {
    const reverseGeocode = async (input: string) => {
      const places = await getSearchedPlaceCode(input);
      if (
        places.features[0] !== undefined &&
        places.features[0].place_type[0] === 'country'
      ) {
        setCountryCode(places.features[0].properties.short_code.toUpperCase());
        setRegionFilter(
          countriesCodes[
            places.features[0].properties.short_code.toUpperCase() as CountryProperty
          ]
        );
      } else setRegionFilter(places.features[0].bbox);
    };
    if (
      regionFilter === undefined &&
      searchedText !== undefined &&
      searchedText !== null
    ) {
      reverseGeocode(decodeURIComponent(searchedText));
    }
    // eslint-disable-next-line
  }, []);

  const [filters] = useQueryParams({
    [QUERY_PARAM_SMARTRACKS_ENABLED]: BooleanParam,
    [QUERY_PARAM_INDOOR_OUTDOOR]: StringParam,
    [QUERY_PARAM_AREA_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_SURFACE_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_AGILITY]: DelimitedArrayParam,
  });
  const [searchParams] = useSearchParams();

  const params: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const matchCases = (value: string) =>
    value === 'grouped' || value === 'smart';
  const testDefaultUrl = Object.keys(params).every(matchCases);
  const { filteredSportsLocations, indoorOutdoorCounts } = React.useMemo(() => {
    const filteredWithoutIndoorOutdoor =
      data === undefined
        ? []
        : data
            .filter((location) =>
              filterSmarTracksEnabled(
                filters[QUERY_PARAM_SMARTRACKS_ENABLED],
                location
              )
            )
            .filter(
              (location) =>
                filters[QUERY_PARAM_AREA_TYPE]?.every((f) =>
                  f !== null ? filterFunctionalAreas(f, location) : true
                ) ?? true
            )
            .filter(
              (location) =>
                filters[QUERY_PARAM_AGILITY]?.every((f) =>
                  f !== null ? filterAgilityTests(f, location) : true
                ) ?? true
            )
            .filter((location) =>
              filterRegion(
                {
                  boundingBox: Array.isArray(regionFilter)
                    ? (regionFilter as number[])
                    : undefined,
                  countryPhoneCode:
                    typeof regionFilter === 'string' ? regionFilter : undefined,
                },
                location,
                countryCode
              )
            )
            .filter(
              (location) =>
                filters[QUERY_PARAM_SURFACE_TYPE]?.every((f) =>
                  f !== null ? filterSurfaceType(f, location) : true
                ) ?? true
            );
    return {
      filteredSportsLocations: filteredWithoutIndoorOutdoor.filter((location) =>
        filterIndoorOutdoor(filters[QUERY_PARAM_INDOOR_OUTDOOR], location)
      ),
      indoorOutdoorCounts: [
        filteredWithoutIndoorOutdoor.filter((location) =>
          filterIndoorOutdoor(undefined, location)
        ).length,
        filteredWithoutIndoorOutdoor.filter((location) =>
          filterIndoorOutdoor('indoor', location)
        ).length,
        filteredWithoutIndoorOutdoor.filter((location) =>
          filterIndoorOutdoor('outdoor', location)
        ).length,
      ],
    };
  }, [data, filters, regionFilter, countryCode]);

  const [mapDimensions, setMapDimensions] = React.useState<{
    width: number;
    height: number;
  }>({ width: window.innerWidth, height: window.innerHeight });

  const handleGeocoderViewportChange = (feature: Feature) => {
    if (feature && feature.bbox) {
      const newViewport: LatLngZoom = new WebMercatorViewport({
        width: mapDimensions.width,
        height: mapDimensions.height,
      }).fitBounds(
        [
          [feature.bbox[0], feature.bbox[1]],
          [feature.bbox[2], feature.bbox[3]],
        ],
        {
          padding: 15,
        }
      );
      mapRef.current?.flyTo({
        center: [newViewport.longitude, newViewport.latitude],
        zoom: newViewport.zoom,
        duration: 2000,
      });
    }
  };
  const handleViewLocation = async (location: SportsLocation | undefined) => {
    setTemporaryLocations(data!);
    setRegionFilter(undefined);
    try {
      if (location) {
        const georectangle = await getSportsLocationSvgGeorectangle(
          location.sportsLocationGUID,
          'NoText'
        );
        const newViewport: LatLngZoom = new WebMercatorViewport({
          width: mapDimensions.width,
          height: mapDimensions.height,
        }).fitBounds(
          [
            [georectangle.northWest.longitude, georectangle.northWest.latitude],
            [georectangle.southEast.longitude, georectangle.southEast.latitude],
          ],
          {
            padding: 15,
          }
        );

        mapRef.current?.flyTo({
          center: [newViewport.longitude, newViewport.latitude],
          zoom: newViewport.zoom,
          duration: 2000,
        });

        if (location !== undefined) {
          const locationName = location.names[0]?.shortName ?? '';
          if (locationName !== '')
            setSmartracksLocationName(encodeURIComponent(locationName));
        }
      }
    } catch (e) {
      console.log('Error while flying to the sports location ', e);
    }
  };
  /**
   * The viewport of the map has to be updated whenever the window size changes.
   * On mobile, this needs to be skipped, when the geocoder is used (usually a keyboard is visible).
   */

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const closeFacilityInfo = () => setSmartracksLocationName(undefined);
    const map = mapRef.current;

    if (mobileScreen) {
      if (facility) {
        map?.on('click', closeFacilityInfo);
      }
    }

    const handleResize = () => {
      setMapDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    // Provide a cleanup function to remove the subscription
    // when the component is cleaned up.
    return () => {
      window.removeEventListener('resize', handleResize);
      if (mobileScreen) {
        map?.off('click', closeFacilityInfo);
      }
    };
  });

  const [filterApplied] = useQueryParam('filtered', NumberParam);
  const [temporaryLocations, setTemporaryLocations] = React.useState(
    data === undefined ? [] : data
  );

  React.useEffect(() => {
    if (testDefaultUrl && data)
      setTemporaryLocations(data?.filter((e) => e.smarTracksEnabled));
    else setTemporaryLocations(filteredSportsLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterApplied, data, searchText, facility]);

  return (
    <Box>
      {/** The map sits below everything at zIndex 0, since Material UI uses a zIndex > 0 by default */}
      <Box zIndex="0">
        <Map
          handleViewLocation={handleViewLocation}
          mapRef={mapRef}
          mapStyle={mapStyle}
          sportsLocations={facility && data ? data : temporaryLocations}
          onRegionSelected={handleRegionFilterChange}
        />
      </Box>
      <Box>
        {/** Top section */}
        <Stack
          position="absolute"
          top={0}
          left={0}
          sx={{ right: [0, 'unset'] }}
        >
          <AppContent
            mapRef={mapRef}
            sportsLocations={data}
            filteredSportsLocations={temporaryLocations}
            counts={indoorOutdoorCounts}
            onLocationSelected={handleViewLocation}
            onRegionSelected={handleRegionFilterChange}
            onViewportChanged={handleGeocoderViewportChange}
          />
        </Stack>
        {/** Bottom left section */}
        <Box
          position="absolute"
          bottom={35}
          left={0}
          zIndex={2}
          sx={{ maxHeight: '30px' }}
        >
          <Branding />
        </Box>
        {/** Bottom right section */}
        <Box position="absolute" bottom={0} right={0}></Box>
      </Box>
    </Box>
  );
};
export default Root;
