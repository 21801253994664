import React from 'react';
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';

import SmartracksMobileLogo from '../../assets/smartracks-logo-mobile.svg';
import SmartracksLogo from '../../assets/smartracks-logo.svg';

import AppOptions from '../AppOptions/AppOptions';
import LocationsFilter from '../LocationsFilter/LocationsFilter';
import {
  Feature,
  SportsLocation,
} from '../../services/SportsLocation/SportsLocation.service';

import SearchIcon from '@mui/icons-material/Search';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneIcon from '@mui/icons-material/Tune';

import SearchLocation from '../SearchLocation/SearchLocation';
import {
  QUERY_PARAM_AREA_TYPE,
  QUERY_PARAM_INDOOR_OUTDOOR,
  QUERY_PARAM_SURFACE_TYPE,
  QUERY_PARAM_AGILITY,
  QUERY_PARAM_FACILITY,
} from '../../services/Filters/Filters.service';
import {
  useQueryParams,
  StringParam,
  DelimitedArrayParam,
} from 'use-query-params';
import { MapRef } from 'react-map-gl';
import FloatingCounter from '../FloatingCounterCard/FloatingCounterCard';

interface AppContentProps {
  sportsLocations?: SportsLocation[];
  filteredSportsLocations?: SportsLocation[];
  counts?: number[];
  openMobileFilter?: (open: boolean) => boolean;
  onLocationSelected?: (location: SportsLocation | undefined) => void;
  onRegionSelected?: (regionFilter: string | number[] | undefined) => void;
  onViewportChanged?: (feature: Feature) => void;
  mapRef: React.RefObject<MapRef>;
}

const AppContent = (props: AppContentProps) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const screenOrientation = window.matchMedia('(orientation: landscape)');
  const screenHeight = window.screen.height;

  const { onLocationSelected, onRegionSelected, mapRef } = props;

  const [mobileButtonsState, setMobileButtonsState] = React.useState({
    filterMenu: false,
    searchBar: false,
    appOptions: false,
  });

  const toggleState = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e.currentTarget?.id === 'app_options') {
      setMobileButtonsState({
        searchBar: false,
        filterMenu: false,
        appOptions: !mobileButtonsState.appOptions,
      });
    }
    if (e.currentTarget?.id === 'geo_coding') {
      setMobileButtonsState({
        filterMenu: false,
        appOptions: false,
        searchBar: !mobileButtonsState.searchBar,
      });
    }
    if (e.currentTarget?.id === 'locations_filter') {
      setMobileButtonsState({
        appOptions: false,
        searchBar: false,
        filterMenu: !mobileButtonsState.filterMenu,
      });
    }
  };

  const [filters] = useQueryParams({
    [QUERY_PARAM_INDOOR_OUTDOOR]: StringParam,
    [QUERY_PARAM_AREA_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_SURFACE_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_AGILITY]: DelimitedArrayParam,
    [QUERY_PARAM_FACILITY]: StringParam,
  });

  const [isSearchInputSet, setIfSearchInputSet] = React.useState('');

  const [filterMenuOpened, setIfFilterMenuOpened] = React.useState(false);

  const landscapePrimarySecondary = screenOrientation.matches;
  return (
    <Container
      maxWidth={mobileScreen ? 'sm' : 'xs'}
      sx={{
        marginX: '0px',
        paddingX: [theme.spacing(0), theme.spacing(1)],
      }}
    >
      <AppBar
        sx={{
          backgroundColor: mobileScreen ? 'white' : 'transparent',
          zIndex: [theme.zIndex.drawer - 2, theme.zIndex.drawer + 2],
        }}
        elevation={0}
        position="relative"
      >
        <Toolbar
          sx={{
            paddingX: '0px !important',
            alignItems: ['center', 'normal'],
          }}
        >
          <Stack
            sx={{
              zIndex: [5, 'normal'],
              minHeight: ['24px', '2.5rem !important'],
              maxHeight: ['24px', '2.5rem !important'],
              backgroundColor: 'white',
              justifyContent: 'space-between',
              width: ['100%', 'normal'],
              marginTop: [0, 2.4],
              boxShadow: [
                'none',
                'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
              ],
            }}
            direction={'row'}
          >
            <img
              style={{
                cursor: 'pointer',
                height: mobileScreen ? '3.5rem' : '2.5rem',
                borderRadius: theme.shape.borderRadius,
                position: mobileScreen ? 'relative' : 'static',
                top: mobileScreen ? -16 : 'normal',
              }}
              src={mobileScreen ? SmartracksMobileLogo : SmartracksLogo}
              alt="SmarTracks Locations Logo"
              onClick={(_) => window.open('https://smartracks.run/', '_blank')}
            />

            {mobileScreen && (
              <>
                <IconButton
                  id="geo_coding"
                  onClick={(e) => toggleState(e)}
                  sx={{
                    zIndex: 3,
                  }}
                >
                  <SearchIcon />
                  {isSearchInputSet !== '' && mobileScreen && (
                    <Box
                      sx={{
                        borderRadius: '100px !important',
                        margin: '-1px !important',
                        padding: '-1px !important',
                      }}
                      border={2}
                      borderColor="red"
                      height={3}
                      width={3}
                      bgcolor="red"
                      color="red"
                      fontSize={34}
                    />
                  )}
                </IconButton>
                <IconButton
                  id="locations_filter"
                  onClick={(e) => {
                    toggleState(e);
                  }}
                  sx={{
                    zIndex: 3,
                  }}
                >
                  <TuneIcon />
                  {filters[QUERY_PARAM_AREA_TYPE] !== undefined ||
                  filters[QUERY_PARAM_INDOOR_OUTDOOR] !== undefined ||
                  filters[QUERY_PARAM_SURFACE_TYPE] !== undefined ||
                  filters[QUERY_PARAM_AGILITY] !== undefined ? (
                    <Box
                      sx={{
                        borderRadius: '100px !important',
                        margin: '-1px !important',
                        padding: '-1px !important',
                      }}
                      border={2}
                      borderColor="red"
                      height={3}
                      width={3}
                      bgcolor="red"
                      color="red"
                      fontSize={34}
                    />
                  ) : null}
                </IconButton>
                <IconButton
                  id="app_options"
                  onClick={(e) => toggleState(e)}
                  sx={{
                    zIndex: 3,
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      {!filterMenuOpened && !mobileButtonsState.filterMenu && (
        <Box
          sx={{
            position: ['fixed', 'fixed'],
            top: [null, theme.spacing(20)],
            left: [null, theme.spacing(1)],
            width: '120px !important',
            paddingTop: 1,
            zIndex: theme.zIndex.drawer - 1,
            border: 'transparent',
          }}
        >
          <FloatingCounter
            totalLocationsNumber={props.sportsLocations?.length}
            totalSmartracksLocationsNumber={
              props.filteredSportsLocations?.length
            }
            hGrade="h5"
            fontWeight={600}
          />
        </Box>
      )}
      <Box
        zIndex={7}
        sx={
          mobileScreen && mobileButtonsState.appOptions
            ? {
                paddingX: '2px ',
                display: 'flex',
                justifyContent: 'end',
                paddingTop: '2px',
              }
            : {
                position: [null, 'fixed'],
                right: [
                  null,
                  landscapePrimarySecondary && screenHeight < 365
                    ? theme.spacing(9)
                    : theme.spacing(1.5),
                ],
                top: [
                  null,
                  landscapePrimarySecondary && screenHeight < 365
                    ? theme.spacing(1.5)
                    : theme.spacing(1),
                ],
                display: mobileScreen ? 'none' : 'flex',
              }
        }
      >
        <AppOptions />
      </Box>

      {/** Accomodate for the geocoder that is added by the Map component in this spot */}
      <Box mt="0px">
        <LocationsFilter
          onRegionSelected={onRegionSelected}
          closeAfterApply={(e: boolean) => {
            setMobileButtonsState({
              ...mobileButtonsState,
              filterMenu: !mobileButtonsState.filterMenu,
            });
          }}
          openMobileMenu={mobileButtonsState.filterMenu}
          {...props}
          mapRef={mapRef}
          drawerStateCallBack={(drawerState) =>
            setIfFilterMenuOpened(drawerState)
          }
        />
      </Box>

      <Box
        zIndex={theme.zIndex.drawer + 3}
        sx={{
          position: ['fixed', 'fixed'],
          top: [null, theme.spacing(12)],
          left: [null, theme.spacing(1)],
          width: ['100%', '30%'],
        }}
      >
        <SearchLocation
          inputCleanedCallback={(inputValue) => {
            if (inputValue === '')
              setMobileButtonsState({
                filterMenu: false,
                appOptions: false,
                searchBar: !mobileButtonsState.searchBar,
              });
            setIfSearchInputSet(inputValue);
          }}
          mapRef={mapRef}
          mobileButton={mobileButtonsState.searchBar}
          sportsLocations={props.sportsLocations}
          onLocationSelected={(location: SportsLocation | undefined) => {
            if (location && onLocationSelected)
              return onLocationSelected(location);
          }}
          onRegionSelected={onRegionSelected}
          onViewportChanged={props.onViewportChanged}
          inputSet={(i) => setIfSearchInputSet(i)}
        />
      </Box>
    </Container>
  );
};

export default AppContent;
