import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  getSportsLocationSvgString,
  SportsLocation,
} from '../../services/SportsLocation/SportsLocation.service';
import { copyToClipboard } from '../../utils/utils';

interface DownloadLocationPlanProps {
  sportsLocation: SportsLocation;
}
interface AlertProps {
  onAlertClose(): void;
  alertOpen: boolean;
  locationName?: string;
}
const DownloadLocationPlan = (props: DownloadLocationPlanProps) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState<string>('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const toggleAlert = () => {
    setAlertOpen(!alertOpen);
  };

  React.useEffect(() => {
    const getSelectedLocationSvgString = async () => {
      try {
        const svgData = await getSportsLocationSvgString(
          props.sportsLocation.sportsLocationGUID,
          'notext'
        );
        setData(svgData);
      } catch (error) {
        console.log(error);
        toggleAlert();
      }
    };
    getSelectedLocationSvgString();
    // eslint-disable-next-line
  }, []);

  function AlertErrorDialog(props: AlertProps) {
    const { onAlertClose, alertOpen } = props;
    const handleAlertClose = () => {
      onAlertClose();
    };
    return (
      <Dialog
        onClose={handleAlertClose}
        aria-labelledby="alert-Error-dialog-title"
        open={alertOpen}
      >
        <DialogTitle id="alert-Error-dialog-title">
          {t('somethingWentWrongWhileTryingToDownloadLocationPlan')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleAlertClose}>ok</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const [downloadFormat, setDownloadFormat] = React.useState('svg');

  const handleDownloadFormatChange = (event: SelectChangeEvent) => {
    setDownloadFormat(event.target.value as string);
  };
  const [downloadFormatDialogOpen, setDownloadFormatDialogOpen] =
    React.useState(false);

  function AlertDownloadFormatDialog(props: AlertProps) {
    const { onAlertClose, alertOpen } = props;
    const handleAlertClose = () => {
      onAlertClose();
    };
    return (
      <Dialog
        onClose={handleAlertClose}
        aria-labelledby="alert-Error-dialog-title"
        open={alertOpen}
      >
        <DialogTitle id="alert-Error-dialog-title">
          {t('fileTypeOfLocationPlan')}
        </DialogTitle>
        <Box m={2}>
          <FormControl fullWidth>
            <InputLabel>Format</InputLabel>
            <Select
              value={downloadFormat}
              label="format"
              onChange={handleDownloadFormatChange}
            >
              <MenuItem value={'png'}>PNG</MenuItem>
              <MenuItem value={'svg'}>SVG</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <DialogActions>
          <Button onClick={() => setDownloadFormatDialogOpen(false)}>
            {t('cancel')}
          </Button>
          <Button onClick={() => handleDownlaodClicked()}>ok</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const [attributionDialogOpen, setAttributionDownloadOpen] =
    React.useState(false);

  function AlertLicensingAttributionDialog(props: AlertProps) {
    const { onAlertClose, alertOpen } = props;
    const handleAlertClose = () => {
      onAlertClose();
    };

    const htmlAttributionSnippet = `<small>
    <a href={${document.URL.split('?')[0]}}>
    ${props.locationName}
    </a>
    <a href={${'https://smartracks.run/'}}>
    @smartracks
    </a>
  </small>`.trim();

    return (
      <Dialog
        onClose={handleAlertClose}
        aria-labelledby="alert-Error-dialog-title"
        open={alertOpen}
        fullWidth
      >
        <DialogTitle id="alert-Download-dialog-title">
          {t('attributionRequired')}
        </DialogTitle>
        <DialogContent sx={{ mb: 0, pb: 1 }}>
          <DialogContentText>
            <Typography sx={{ wordWrap: 'break-word' }}>
              {t(
                'whenUsingThisLocationPlanElseWhere,PleaseUseTheFollowingAttribution'
              ) + ': '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={document.URL.split('?')[0]}
              >
                {' ' + props.locationName}
              </a>

              {'  '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://smartracks.run/'}
              >
                @smartracks
              </a>
              {' ' + t('by')}
              {' Humotion'}
              <Box sx={{ mt: 6 }} />
            </Typography>
            <Typography variant={'subtitle1'}>
              {t('toEmbeddAttribution') + ':'}
            </Typography>
          </DialogContentText>
          <Box
            sx={{ backgroundColor: '#f2f2f2', width: ['fit-content', '100%'] }}
          >
            <Typography component={'pre'} variant={'subtitle2'}>
              {htmlAttributionSnippet}
            </Typography>
          </Box>
        </DialogContent>
        <Button
          variant="outlined"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            ml: 4,
            '&:hover': {
              backgroundColor: '#fff',
            },
            maxWidth: 'fit-content',
            borderTopRightRadius: '10px 10px',
            borderBottomRightRadius: '10px 10px',
            borderTopLeftRadius: '10px 10px',
            borderBottomLeftRadius: '10px 10px',
          }}
          onClick={() => copyToClipboard(htmlAttributionSnippet)}
        >
          {t('copyToClipboard')}
        </Button>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              borderRadius: '10px',
              maxWidth: 'fit-content',
            }}
            onClick={handleAlertClose}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const downloadLocationPlan = (href: string, name: string, format: string) => {
    let anchor = document.createElement('a');
    anchor.style.opacity = '0';
    anchor.href = href;
    anchor.download = name + `.` + format;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(href);
    setTimeout(() => setAttributionDownloadOpen(true), 1800);
  };

  const handleDownlaodClicked = () => {
    setDownloadFormatDialogOpen(false);

    if (data !== '') {
      let svgBlob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8',
      });
      let blobUrl = URL.createObjectURL(svgBlob);
      if (downloadFormat === 'png') {
        //first make an SVG Doc with Elements Nodes to extract needed values
        let parser = new DOMParser();
        let doc = parser.parseFromString(data, 'image/svg+xml');
        //choose the SVG Element with childs
        let svgElement = doc.getElementsByTagName('svg');
        let svgWidthPropertie = svgElement[0].getAttribute('width');
        let svgHeightPropertie = svgElement[0].getAttribute('height');
        let viewBox = svgElement[0].getAttribute('viewBox');
        let viewBoxSvgWidth = (viewBox as string).split(' ')[2];
        let viewBoxSvgHeight = (viewBox as string).split(' ')[3];
        //extract Width and Height needed for drwaing the PNG Image on Canvas
        let svgWidth = parseFloat(
          svgWidthPropertie === null ? viewBoxSvgWidth : svgWidthPropertie
        );
        let svgHeight = parseFloat(
          svgHeightPropertie === null ? viewBoxSvgHeight : svgHeightPropertie
        );

        let image = new Image();
        // make canavas on the fly and draw svg to be downloaded
        image.onload = () => {
          let canvas = document.createElement('canvas');
          if (svgWidth < 1500 || svgHeight < 900) {
            canvas.width = svgWidth * 15;
            canvas.height = svgHeight * 15;
          } else {
            canvas.width = svgWidth;
            canvas.height = svgHeight;
          }

          let context = canvas.getContext('2d');
          context!.fillStyle = 'white';
          context!.fillRect(0, 0, canvas.width, canvas.height);
          // draw image in canvas starting left-0 , top - 0
          context?.drawImage(
            image,
            0,
            0,
            image.width < svgWidth ? svgWidth : image.width,
            image.height < svgHeight ? svgHeight : image.height,
            0,
            0,
            canvas.width,
            canvas.height
          );
          let png = canvas.toDataURL();
          downloadLocationPlan(
            png,
            `${props.sportsLocation.names[0].shortName}`,
            'png'
          );
        };
        image.src = blobUrl;
      } else {
        downloadLocationPlan(
          blobUrl,
          `${props.sportsLocation.names[0].shortName}`,
          'svg'
        );
      }
    } else {
      toggleAlert();
    }
  };

  return (
    <>
      <Box
        sx={{
          marginY: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!props.sportsLocation && (
          <Skeleton
            sx={{
              borderRadius: '10px',
              position: 'absolute',
              bottom: 0,
              marginBottom: '5px',
            }}
            variant="rectangular"
            width="60%"
            height="30px"
          />
        )}
        {props.sportsLocation && (
          <>
            {' '}
            <Button
              onClick={() => setDownloadFormatDialogOpen(true)}
              variant="outlined"
              sx={{
                borderColor: '#A9A9A9',
                color: '#696969',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                width: 'fit-content',
              }}
            >
              {t('downloadLocationPlan')}
            </Button>
            <AlertErrorDialog
              alertOpen={alertOpen}
              onAlertClose={toggleAlert}
            />
            <AlertLicensingAttributionDialog
              alertOpen={attributionDialogOpen}
              onAlertClose={() => setAttributionDownloadOpen(false)}
              locationName={props.sportsLocation.names[0].shortName ?? ''}
            />
            <AlertDownloadFormatDialog
              alertOpen={downloadFormatDialogOpen}
              onAlertClose={() => setDownloadFormatDialogOpen(false)}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default DownloadLocationPlan;
