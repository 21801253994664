import { Box, IconButton } from '@mui/material';
import { Public } from '@mui/icons-material';

export type RecenterProps = {
  label?: string;
  onClick: () => void;
};

const Recenter = (props: RecenterProps) => {
  return (
    <Box
      sx={{
        backgroundColor: '#f7f7f7',
        borderRadius: '4px', // Use 4px as that is what mapbox uses
        marginBottom: [0,'30px'], // Makes room for the mapbox contribution ctrl
        marginRight: '10px',
        height: '29px',
        width: '29px',
      }}
    >
      <IconButton
        sx={{
          maxWidth: '29px',
          maxHeight: '29px',
          minHeight: '29px',
          minWidth: '29px !important',
          backgroundColor: '#FFFFFF',
          boxShadow: ' 0 0 0 2px rgb(0 0 0/10%)',
          borderRadius: '4px', // Use 4px as that is what mapbox uses
        }}
        type="button"
        title={props.label}
        onClick={(_) => props.onClick()}
        size="large"
      >
        <Public style={{ color: '#404040' }} />
      </IconButton>
    </Box>
  );
};
export default Recenter;
