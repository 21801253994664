export class AuthError extends Error {
  name: string;
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class HTTPError extends Error {
  name: string;
  statusCode?: number;
  constructor(status?: number, message?: string) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.statusCode = status;
  }
}

export class JSONDecodeError extends Error {
  name: string;
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
